// React
import { useState, useEffect } from 'react';
// Imports
import axios from 'axios';
import { DateTime } from "luxon";
// Components
import FetchServiceLines from "./components/FetchServiceLines";
import CalculateSummaryData from "./components/CalculatedSummaryDashboard";

const ServiceLineData = (url, headers) => {
  const [serviceLine, setServiceLine] = useState({});
  const [graphData, setGraphData] = useState({});
  const [summaryData, setSummaryData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchAllData = async () => {
      setIsLoading(true);
      try {
        const [serviceResponse, permissionsResponse] = await Promise.all([
          axios.get(`${url}/api/service_line`, { headers }), //Actualizar este dato, acá está nickname, cliente, cliente final
          axios.get(`${url}/auth/perfil`, { headers })
        ]);
        const serviceLines = serviceResponse.data.datos.map(service => service.serviceLineNumber);
        const serviceLinesCliente = serviceResponse.data.datos.map(service => service.cliente.nombre); // Actualizar
        const serviceLinesClienteFinal = serviceResponse.data.datos.map(service => service.cliente_final); // Static
        const serviceLinesNickName = serviceResponse.data.datos.map(service => service.nickname); // Actualizar
        const serviceLinesKitNumber = serviceResponse.data.datos.map(service => service.userTerminals[0].kitSerialNumber);
        // Api de niveles
        const lineas = permissionsResponse?.data?.datos?.permisos?.starlink?.lineas;
        const serviceLineLevels = lineas && lineas.length > 0
          ? lineas.reduce((acc, line) => {
              acc[line.serviceLineNumber] = line.nivel;
              return acc;
            }, {})
          : 4; //Considerando que 4 es el permiso máximo y que será solo para gente que no tiene equipos, el admin

        // console.log(serviceLineLevels);

        const allserviceLine = await Promise.all(
          serviceLines.map((serviceLineNumber, index) =>
            FetchServiceLines(serviceLineNumber, serviceLinesNickName[index], serviceLinesClienteFinal[index], serviceLinesCliente[index], serviceLinesKitNumber[index], url, headers, serviceLineLevels)
          )
        );

        // Acá se combinan los datos de las peticionea anteriores
        const combinedserviceLine = allserviceLine.reduce((acc, data) => ({ ...acc, ...data }), {});

        const newGraphData = {};
        const newGraphData_2 = {};
        Object.keys(combinedserviceLine).forEach(serviceLineNumber => {
          const { billingCycles, serviceLines } = combinedserviceLine[serviceLineNumber];

          serviceLines.forEach(serviceLine => {
            const telemetryData = serviceLine.telemetryData;

            newGraphData_2[serviceLine.serviceLineNumber] = {
              KitNumber: serviceLine.kitNumber,
              KitName: serviceLine.clienteName,
              KitCliente: serviceLine.cliente,
              KitClienteFinal: serviceLine.cliente_final,
              telemetryData: telemetryData ? {
                downlinkThroughput: telemetryData.slice(0, 15).map(d => ({
                  name: new Date(d.fecha).toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' }),
                  Mbps: parseFloat((d.downlink_throughput ?? 0).toFixed(1))
                })).reverse(),
                downlinkThroughputStatsShort: (() => {
                  if (telemetryData.length === 0) return 0;
                  const detail = telemetryData.slice(0, 15).map(d => parseFloat((d.downlink_throughput ?? 0).toFixed(1)));
                  const max = detail.length > 0 ? Math.max(...detail) : 0;
                  const limit = Math.ceil(max / 5) * 5 ;
                  return {
                    min: detail.length > 0 ? Math.min(...detail) + " Mbps" : 0,
                    max: detail.length > 0 ? Math.max(...detail) + " Mbps" : 0,
                    avg: detail.length > 0 ? parseFloat((detail.reduce((sum, d) => sum + d, 0) / detail.length).toFixed(1)) + " Mbps" : 0,
                    limit: limit
                  };
                })(),

                downlinkThroughputDetail: telemetryData.slice(0, 60).map(d => ({ 
                  name: new Date(d.fecha).toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' }),
                  Mbps: parseFloat((d.downlink_throughput ?? 0).toFixed(1)) 
                })).reverse(),
                downlinkThroughputStats: (() => {
                  if (telemetryData.length === 0) return 0;
                  const detail = telemetryData.slice(0, 60).map(d => parseFloat((d.downlink_throughput ?? 0).toFixed(1)));
                  const max = detail.length > 0 ? Math.max(...detail) : 0;
                  const limit = Math.ceil(max / 5) * 5 ;
                  return {
                    min: detail.length > 0 ? Math.min(...detail) + " Mbps" : 0,
                    max: detail.length > 0 ? Math.max(...detail) + " Mbps" : 0,
                    avg: detail.length > 0 ? parseFloat((detail.reduce((sum, d) => sum + d, 0) / detail.length).toFixed(1)) + " Mbps" : 0,
                    limit: limit,
                    signal: detail.length > 0 && Math.max(...detail) !== 0 ? true : false
                  };
                })(),
                //
                uplinkThroughput: telemetryData.slice(0, 60).map(d => ({
                  name: new Date (d.fecha).toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' }),
                  value: parseFloat((d.uplink_throughput ?? 0).toFixed(1))
                })).reverse(),
                uplinkThroughputStats: (() => {
                  if (telemetryData.length === 0) return 0;
                  const detail = telemetryData.slice(0, 60).map(d => parseFloat((d.uplink_throughput ?? 0).toFixed(1)));
                  const max = detail.length > 0 ? Math.max(...detail) : 0;
                  const limit = Math.ceil(max / 5) * 5 ;
                  return {
                    min: detail.length > 0 ? Math.min(...detail) + " Mbps" : 0,
                    max: detail.length > 0 ? Math.max(...detail) + " Mbps" : 0,
                    avg: detail.length > 0 ? parseFloat((detail.reduce((sum, d) => sum + d, 0) / detail.length).toFixed(1)) + " Mbps" : 0,
                    limit: limit
                  };
                })(),
                //
                pingLatency: telemetryData.slice(0, 60).map(d => ({
                  name: new Date (d.fecha).toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' }),
                  ms: parseFloat((d.ping_latency ?? 0).toFixed(1))
                })).reverse(),
                pingLatencyStats: (() => {
                  if (telemetryData.length === 0) return 0;
                  const detail = telemetryData.slice(0, 60).map(d => parseFloat((d.ping_latency ?? 0).toFixed(1)));
                  const max = detail.length > 0 ? Math.max(...detail) : 0;
                  const limit = Math.ceil(max / 50) * 50 ;
                  return {
                    min: detail.length > 0 ? Math.min(...detail) + " ms" : 0,
                    max: detail.length > 0 ? Math.max(...detail) + " ms" : 0,
                    avg: detail.length > 0 ? parseFloat((detail.reduce((sum, d) => sum + d, 0) / detail.length).toFixed(1)) + " ms" : 0,
                    limit: limit
                  };
                })(),
                //
                pingDropRate: telemetryData.slice(0, 60).map(d => ({
                  name: new Date (d.fecha).toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' }),
                  ms: parseFloat((d.ping_drop_rate ?? 0).toFixed(1))
                })).reverse(),
                pingDropRateStats: (() => {
                  if (telemetryData.length === 0) return 0;
                  const detail = telemetryData.slice(0, 60).map(d => parseFloat((d.ping_drop_rate ?? 0).toFixed(1)));
                  const max = detail.length > 0 ? Math.max(...detail) : 0;
                  const limit = Math.ceil(max / 50) * 50 ;
                  return {
                    min: detail.length > 0 ? Math.min(...detail) + " ms" : 0,
                    max: detail.length > 0 ? Math.max(...detail) + " ms" : 0,
                    avg: detail.length > 0 ? parseFloat((detail.reduce((sum, d) => sum + d, 0) / detail.length).toFixed(1)) + " ms" : 0,
                    limit: limit
                  };
                })(),
                //
                snrAvg: telemetryData.slice(0, 60).map(d => ({
                  name: new Date (d.fecha).toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' }),
                  Porcentaje: parseFloat((d.snr_avg ?? 0).toFixed(2)) *100
                })).reverse(),
                snrAvgStats: (() => {
                  if (telemetryData.length === 0) return 0;
                  const detail = telemetryData.slice(0, 60).map(d => parseFloat((d.snr_avg ?? 0).toFixed(1)));
                  return {
                    min: detail.length > 0 ? Math.min(...detail)*100 + "%" : 0,
                    max: detail.length > 0 ? Math.max(...detail)*100 + "%" : 0,
                    avg: detail.length > 0 ? parseFloat((detail.reduce((sum, d) => sum + d, 0) / detail.length).toFixed(1))*100 + "%" : 0
                  };
                })(),
                //
                obstructionTime: telemetryData.slice(0, 60).map(d => ({
                  name: new Date (d.fecha).toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' }),
                  porcentaje: parseFloat((d.obstruction_time ?? 0).toFixed(1))
                })).reverse(),
                obstructionTimeStats: (() => {
                  if (telemetryData.length === 0) return 0;
                  const detail = telemetryData.slice(0, 60).map(d => parseFloat((d.obstruction_time ?? 0).toFixed(1)));
                  return {
                    min: detail.length > 0 ? Math.min(...detail) + " ms" : 0,
                    max: detail.length > 0 ? Math.max(...detail) + " ms" : 0,
                    avg: detail.length > 0 ? parseFloat((detail.reduce((sum, d) => sum + d, 0) / detail.length).toFixed(1)) + " ms" : 0
                  };
                })(),
                maxValues: {
                  uplinkThroughput: Math.max(...telemetryData.map(d => (d.uplink_throughput_max ?? 0)).map(v => parseFloat(v.toFixed(1)))),
                  pingLatency: Math.max(...telemetryData.map(d => (d.ping_latency_max ?? 0)).map(v => parseFloat(v.toFixed(1)))),
                  pingDropRate: Math.max(...telemetryData.map(d => (d.ping_drop_rate_max ?? 0)).map(v => parseFloat(v.toFixed(1)))),
                  snrAvg: Math.max(...telemetryData.map(d => (d.snr_avg_max ?? 0)).map(v => parseFloat(v.toFixed(1)))),
                  obstructionTime: Math.max(...telemetryData.map(d => (d.obstruction_time_max ?? 0)).map(v => parseFloat(v.toFixed(1))))
                },
                minValues: {
                  uplinkThroughput: Math.min(...telemetryData.map(d => (d.uplink_throughput_min ?? 0)).map(v => parseFloat(v.toFixed(1)))),
                },
                averages: {
                  uplinkThroughput: parseFloat((telemetryData.reduce((sum, d) => sum + (d.uplink_throughput ?? 0), 0) / telemetryData.length).toFixed(1)),
                }
              } : null
            };

          });

          // console.log(newGraphData_2);
          
          billingCycles.forEach(billingCycle => {
            const { serviceLineNumber } = billingCycle; // const { serviceLineNumber, telemetry } = billingCycle;

            const downloadData = [];
            const GbData = [];
            const stackedBarData = [];

            if (billingCycle.dailyDataUsage && billingCycle.dailyDataUsage.length > 0) {
              const startDate = DateTime.fromISO(billingCycle.startDate);
              const endDate = DateTime.fromISO(billingCycle.endDate);
              const dateRange = [];
        
              let currentDate = startDate;
              while (currentDate <= endDate) {
                dateRange.push(currentDate);
                currentDate = currentDate.plus({ days: 1 });
              }

              const usageMap = new Map();
              billingCycle.dailyDataUsage.forEach(usage => {
                const date = DateTime.fromISO(usage.date).toFormat('MM-dd');
                usageMap.set(date, {
                  priorityGB: parseFloat((usage.priorityGB + usage.optInPriorityGB).toFixed(0)),
                  standardGB: parseFloat((usage.standardGB + usage.nonBillableGB).toFixed(0))
                });
              });

              dateRange.forEach(date => {
                const formattedDate = date.toFormat('MM-dd');
                const data = usageMap.get(formattedDate) || { priorityGB: 0, standardGB: 0 };

                downloadData.push({
                  name: formattedDate,
                  prioritarios: data.priorityGB,
                  estandar: data.standardGB
                });

                GbData.push({
                  name: formattedDate,
                  prioritarios: data.priorityGB,
                  estandar: data.standardGB
                });

                stackedBarData.push({
                  name: formattedDate,
                  priority: data.priorityGB,
                  standard: data.standardGB
                });
              });
            }

            newGraphData[serviceLineNumber] = {
              StartDate: billingCycle.startDate,
              EndDate: billingCycle.endDate,
              ServiceLineNumber: serviceLineNumber,
              AccountNumber: billingCycle.accountNumber,
              FechaInicio: billingCycle.startDate,
              FechaTermino: billingCycle.endDate,
              TotalPriority: billingCycle["servicePlan.usageLimitGB"],
              GbData: GbData,
              stackedBarData: stackedBarData,
              MaxPriority: stackedBarData.length ? Math.max(Math.max(...stackedBarData.map(d => d.priority)), Math.max(...stackedBarData.map(d => d.standard))) : 0,
              LimitePriority: stackedBarData.length ? Math.ceil(Math.max(...stackedBarData.map(d => d.priority + d.standard)) / 50) * 50 : 100,
              MinPriority: (() => {
                if (!stackedBarData.length) return 0;
                const minValue = Math.min(...stackedBarData.flatMap(d => [d.priority, d.standard].filter(p => p > 0)));
                return isFinite(minValue) ? minValue : 0;
              })(),
              LastPriority: stackedBarData.length > 0 ? (stackedBarData[stackedBarData.length - 1].priority >= 
                stackedBarData[stackedBarData.length - 1].standard ? stackedBarData[stackedBarData.length - 1].priority : 
                stackedBarData[stackedBarData.length - 1].standard) : 0,

              TotalUsage: stackedBarData.reduce((sum, d) => sum + d.priority + d.standard, 0),
              PEstandar: (() => {
                const result = stackedBarData.reduce((acc, d) => {
                  if (d.standard !== 0) {
                    acc.sum += d.standard;
                    acc.count++;
                  }
                  return acc;
                }, { sum: 0, count: 0 });

                return result.count > 0 ? result.sum / result.count : 0;
              })(),
              PPrioritario: (() => {
                const result = stackedBarData.reduce((acc, d) => {
                  if (d.priority !== 0) {
                    acc.sum += d.priority;
                    acc.count++;
                  }
                  return acc;
                }, { sum: 0, count: 0 });
                return result.count > 0 ? result.sum / result.count : 0;
              })(),
            };
          });
        });

        Object.keys(newGraphData).forEach(serviceLineNumber => {
          if (newGraphData_2[serviceLineNumber]) {
            newGraphData[serviceLineNumber] = {
              ...newGraphData[serviceLineNumber],
              KitNumber: newGraphData_2[serviceLineNumber].KitNumber,
              KitName: newGraphData_2[serviceLineNumber].KitName,
              KitCliente: newGraphData_2[serviceLineNumber].KitCliente === "- sin cliente -"
                ? "Sin Cliente"
                : newGraphData_2[serviceLineNumber].KitCliente,
              KitClienteFinal: newGraphData_2[serviceLineNumber].KitClienteFinal,
              telemetryData: newGraphData_2[serviceLineNumber].telemetryData,
              nivel: serviceLineLevels[serviceLineNumber] || 4
            };
          }
        });

        const summaryData = CalculateSummaryData(newGraphData);
        setServiceLine(combinedserviceLine);
        setGraphData(newGraphData);
        setSummaryData(summaryData);
        setIsLoading(false);

      } catch (error) {
        console.error('Error:', error);
        setError('Error al cargar los datos');
        setIsLoading(false);
      }
    };

    fetchAllData();
  }, [url, headers]);

  return { serviceLine, graphData, summaryData, isLoading, error };
};

export default ServiceLineData;