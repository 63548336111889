import React, { useMemo, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Row,
  Col,
} from "reactstrap";
import "./styles/ModalClient.css";
import UseStoreKit from "./UseStoreKit";

const ModalClient = ({
  isOpen,
  toggle,
  Client,
  FinalClient,
  NickName,
  ServiceLine,
}) => {
  const {
    cliente,
    nickname,
    setCliente,
    setNickname,
    saveData,
    // modalParams,
    // setModalParams
  } = UseStoreKit();

  const displayText =
    Client === "Sin Cliente"
      ? `Editar Datos de servicio ${ServiceLine}`
      : `Editar Datos de cliente ${Client}`;

  const url = process.env.REACT_APP_URL_API;
  const token = localStorage.getItem("token");
  const headers = useMemo(
    () => ({
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : "",
    }),
    [token]
  );

  useEffect(() => {
    setCliente(FinalClient);
    setNickname(NickName);
  }, [Client, FinalClient, NickName, ServiceLine, setCliente, setNickname]);

  const handleSave = async () => {
    try {
      const responseData = await saveData(url, ServiceLine, headers);
      console.log("Datos enviados correctamente", responseData);
      toggle();
      window.location.reload(); // Eliminar
    } catch (error) {
      console.error("Error al enviar los datos", error);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="custom-modal">
      <ModalHeader toggle={toggle}>{displayText}</ModalHeader>
      <ModalBody>
        <Row>
          <Col md={6} xs={12}>
            <label htmlFor="inputB" className="form-label">
              Cliente
            </label>
            <Input
              type="text"
              id="inputB"
              value={cliente}
              onChange={(e) => setCliente(e.target.value)}
              className="custom-input"
            />
          </Col>
          <Col md={6} xs={12}>
            <label htmlFor="inputC" className="form-label">
              Mnemónico
            </label>
            <Input
              type="text"
              id="inputC"
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
              className="custom-input"
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-end">
        <Button color="secondary" onClick={toggle}>
          Cancelar
        </Button>
        <Button color="primary" onClick={handleSave}>
          Guardar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalClient;
