// React
import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
// Components
import CustomChart from "./components/CustomChart";
// Styles
import "./styles/DeviceList.css";

const SummaryDashboard = ({ data, graphData }) => {
  const { cantidadEquipos, tiposPlanes, equiposActivos, equiposDatosEstandar } =
    data;
  const colors = [
    "#6a5acdcc",
    "#FFD700cc",
    "#D9822B",
    "#8B4513cc",
    "#FF0000cc",
    "#008000cc",
  ];
  // Cantidad de Equipos
  const chartEquiposActivos = equiposActivos;
  const chartEquiposTotales = cantidadEquipos;
  const chartEquiposInactivos = chartEquiposTotales - chartEquiposActivos;
  //
  const DatosEstandar = equiposDatosEstandar;
  const EquiposTotales = chartEquiposTotales - equiposDatosEstandar;

  const PieChartEquiposActivos = [
    { name: "Equipos Activos", equipos: equiposActivos },
    { name: "Equipos Inactivos", equipos: chartEquiposInactivos },
  ].filter((data) => data.equipos > 0);

  const PieChartEquiposTera = tiposPlanes.map((plan) => {
    const equiposEnEstePlan = Object.values(graphData).filter(
      (kit) => kit.TotalPriority === plan
    ).length;
    return {
      name: `${plan} GB`,
      equipos: equiposEnEstePlan,
    };
  });

  const PieChartEquiposEstandar = [
    { name: "Datos Estándar", equipos: DatosEstandar },
    { name: "Datos Prioritarios", equipos: EquiposTotales },
  ].filter((data) => data.equipos > 0);

  return (
    <Card className="border-0" style={{ backgroundColor: "#2c2c2c" }}>
      <CardBody>
        <div className="summary-table">
          <h4 style={{ textAlign: "center", flex: 1, color: "lightgray" }}>
            Resumen de equipos
          </h4>
          <Row>
            <Col md={4} xs={12}>
              <CustomChart
                title={`Cantidad de Equipos: ${cantidadEquipos}`}
                data={PieChartEquiposActivos}
                type="pie"
                dataKey={"equipos"}
                height={200}
                xAxisFontSize={10}
                colors={["#2EB82E", "#808080"]}
              />
            </Col>
            {window.innerWidth <= 767 && (
              <hr
                style={{
                  border: 0,
                  height: "1px",
                  backgroundColor: "lightgray",
                  marginTop: "5px",
                }}
              />
            )}
            <Col md={4} xs={12}>
              <CustomChart
                title={"Planes de Equipos"}
                data={PieChartEquiposTera}
                type="pie"
                dataKey={"equipos"}
                height={200}
                xAxisFontSize={10}
                colors={colors} // Usamos el array de colores aquí
              />
            </Col>
            {window.innerWidth <= 767 && (
              <hr
                style={{
                  border: 0,
                  height: "1px",
                  backgroundColor: "lightgray",
                  marginTop: "5px",
                }}
              />
            )}
            <Col md={4} xs={12}>
              <CustomChart
                title={"Equipos con datos Estándar"}
                data={PieChartEquiposEstandar}
                type="pie"
                dataKey={"equipos"}
                height={200}
                xAxisFontSize={10}
                colors={["#32CD32", "#007ACC"]}
              />
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  );
};

export default SummaryDashboard;
